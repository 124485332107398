import React from 'react';
import Layout from '../containers/Layout';
import Hero from '../containers/LandingPages/Hero';

const IndexPage: React.FC = () => (
  <Layout
    title="Solvana | The Smart, Fast, Easy way to buy solar for your home or business"
    withoutMarginTop
    withTransparentHeaderBg
    hideHeaderLinks
  >
    <Hero />
  </Layout>
);

export default IndexPage;
